import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import Login from '../pages/Login';

import { AuthProvider } from '../context/auth';

import Register from '../pages/Register';
import Dashboard from '../pages/Dashboard';
import Store from '../pages/Store';
import Relate from '../pages/Relate';
import History from '../pages/History';
import ChangePassword from '../pages/ChangePassword';

const Routes = () => {
  return (
    <Switch>
      <AuthProvider>
        <Route path="/login" exact component={Login} />
        <Route path="/change/:key" exact component={ChangePassword} />
        <Route path="/dashboard" exact isPrivate component={Dashboard} />
        <Route path="/store/:storeKey" exact isPrivate component={Store} />
        <Route path="/relate/:storeKey" exact isPrivate component={Relate} />
        <Route path="/history" exact isPrivate component={History} />
        <Route path="/:storeKey" exact component={Register} />
      </AuthProvider>
    </Switch>
  );
};

export default Routes;
