import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { MdDone } from 'react-icons/md';
import { useAuth } from '../../context/auth';
import api from '../../services/api';
import Select from '../../components/SelectInput';
import Modal from 'react-modal';

import {
  Container,
  Content,
  MenuContent,
  MenuItem,
  MenuItemHeader,
  HeaderClient,
  HeaderAction,
  Footer,
  ButtonOption,
  ButtonAction,
  HeaderPurchase,
  ClientDiv,
  FinishPurchase,
  SelectField,
  SelectStyled,
  InputField,
  HeaderCep,
  HeaderPassword,
  ForgotPass,
  ModalTitle,
  ModalFooter,
  ConfirmButton,
  CancelButton,
  ModalBody,
  ModalItem,
} from './styles';
import { addHours, parseISO } from 'date-fns';

import { Spinner, Row, Col } from 'reactstrap';
import logo from '../../assets/images/fav.png';

const Login = props => {
  const history = useHistory();
  const axios = require('axios');

  const [phone, setCelphone] = useState('');
  const [password, setPassword] = useState(null);
  const [mensagem, setMensagem] = useState('');
  const { signIn, userToken } = useAuth();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [emailClient, setEmailClient] = useState('');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setMensagemEnvio('');
  }

  function handleChangeCelphone(e) {
    setCelphone(e.target.value);
  }

  function handleChangePassword(e) {
    setPassword(e.target.value);
  }

  const [mensagemEnvio, setMensagemEnvio] = useState('');

  async function handleRequestForgot() {
    try {
      const parsedPhone = phone.replace(' ', '').replace('-', '');
      const response = await api.post(
        `/public/request-change-password/${parsedPhone}`,
      );
      setMensagemEnvio('E-mail enviado com sucesso!');
    } catch (e) {
      alert('Erro ao requisitar...');
    }
  }

  async function handleLogin(e) {
    try {
      const parsedPhone = phone.replace(' ', '').replace('-', '');
      console.log(parsedPhone);
      await signIn({ phone: parsedPhone, password });

      if (history.length < 4) {
        history.push('/dashboard');
      } else {
        history.goBack();
      }
    } catch (err) {
      setMensagem(err.response.data.error);
    }
  }

  function parseEmail(email) {
    if (email.length > 0) {
      let sufix = email.substring(email.indexOf('@') + 0);
      let mail = email.substring(0, email.indexOf('@'));
      let mail1 = mail.substring(0, 3);
      let mail2 = mail.substring(mail.length - 3, mail.length);
      let asteriscos = mail.length - 6;
      let astes = '';
      for (var i = 0; i < asteriscos; i++) {
        astes = astes + '*';
      }
      return `${mail1}${astes}${mail2}${sufix}`;
    }
  }

  async function getClient(phone) {
    try {
      const response = await api.get(`/public/clients/${phone}/info`);
      if (response.data.clientEmail) {
        setEmailClient(parseEmail(response.data.clientEmail));
      }
    } catch (err) {
      alert(err);
    }
  }

  useEffect(() => {
    if (phone.length === 15) {
      const parsedPhone = phone.replace(' ', '').replace('-', '');
      getClient(parsedPhone);
    }
  }, [phone]);

  return (
    <Container>
      <Content>
        <div style={{ textAlign: 'center' }}>
          <img src={logo} height="80" width="80" style={{ margin: 'auto' }} />
          <h2>Clube do Cliente</h2>
          <h3>Login</h3>
        </div>

        <HeaderClient>
          <div style={{ textAlign: 'center' }}>Digite seu telefone</div>
          <InputMask
            mask="(99) 99999-9999"
            maskChar=""
            value={phone}
            onChange={e => handleChangeCelphone(e)}
          />
        </HeaderClient>

        <HeaderPassword>
          <div style={{ textAlign: 'center' }}>Digite sua senha</div>
          <InputMask type="password" onChange={e => handleChangePassword(e)} />
        </HeaderPassword>

        <div style={{ color: 'red', textAlign: 'center' }}>
          <span>{mensagem}</span>
        </div>

        <ForgotPass
          onClick={() => {
            openModal();
          }}
        >
          Esqueci minha senha
        </ForgotPass>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Esqueci minha senha"
        >
          <ModalTitle>
            <p>Esqueci minha senha</p>
          </ModalTitle>
          <ModalBody>
            {phone.length < 15 ? (
              <span>Preencha o número de telefone.</span>
            ) : mensagemEnvio === '' ? (
              <>
                <span style={{ textAlign: 'center' }}>
                  Será enviado um link de redefinição via e-mail para o endereço
                  cadastrado no Clube do Cliente de telefone {phone}.
                </span>
                <br />
                <span style={{ textAlign: 'center' }}>{emailClient}</span>
              </>
            ) : (
              <span style={{ color: 'green' }}>{mensagemEnvio}</span>
            )}
          </ModalBody>
          <ModalFooter>
            {mensagemEnvio === '' && phone.length > 14 && (
              <ConfirmButton onClick={() => handleRequestForgot()}>
                Enviar E-mail
              </ConfirmButton>
            )}
            <CancelButton onClick={closeModal}>Cancelar</CancelButton>
          </ModalFooter>
        </Modal>

        <Footer>
          <FinishPurchase>
            <div>
              <button
                className="btn-primary"
                style={{ background: '#03DB9E' }}
                onClick={() => handleLogin()}
              >
                Login
              </button>
            </div>
          </FinishPurchase>
        </Footer>
      </Content>
    </Container>
  );
};

export default Login;
