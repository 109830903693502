import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { MdDone} from 'react-icons/md';
import { useAuth } from '../../context/auth';
import api from '../../services/api';
import Select from '../../components/SelectInput';

import {
  Container,
  Content,
  MenuContent,
  MenuItem,
  MenuItemHeader,
  HeaderClient,
  HeaderAction,
  Footer,
  ButtonOption,
  ButtonAction,
  HeaderPurchase,
  ClientDiv,
  FinishPurchase,
  SelectField,
  SelectStyled,
  InputField,
  HeaderCep,
  HeaderPassword,
  ForgotPass
} from './styles';
import { addHours, parseISO } from 'date-fns';


const ChangePassword = props => {
  const history = useHistory();
  const axios = require('axios');

  const [password, setPassword] = useState(null);
  const [passwordConfirm, setPasswordConfirm] = useState(null);
  const [passwordsAllow, setPasswordsAllow] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [step, setStep] = useState(1);
  
  function handleChangeConfirmPassword(e) {
    setPasswordConfirm(e.target.value);
  }

  function handleChangePassword(e) {
    setPassword(e.target.value);
  }

  const {key} = props.match.params;

  async function handleToLogin(){
    history.push(`/login`);
  }

  async function handleChange(){
    try{
      if(password.length > 5){
        const response = await api.post(`/public/change-password`, {
          key: key,
          password: password,
        });
        setMensagem(response.data.text);
        setStep(2);
      }else{
        setMensagem('A senha deve ter pelo menos 6 dígitos.')
      }
    }catch(err){
      console.log(err, err.response.data.error);
      setMensagem(err.response.data.error);
    }
  }

  useEffect(() => {
    if(passwordConfirm === password && passwordConfirm !== ''){
      setPasswordsAllow(true);
    }else{
      setPasswordsAllow(false);
    }
  }, [passwordConfirm]);

  return (
    <Container>
      <Content>
        <HeaderAction>
          <header>
            <h1>Clube do Cliente</h1>
            <h3>Trocar Senha</h3>
          </header>
        </HeaderAction>

      {step === 1 && <>
        <HeaderPassword>
          <div style={{textAlign: 'center'}}>Digite sua nova senha</div>
          <InputMask
            type="password"
            onChange={e => handleChangePassword(e)}
          />
        </HeaderPassword>

        <HeaderPassword>
          <div style={{textAlign: 'center'}}>Confirme sua nova senha</div>
          <InputMask
            type="password"
            onChange={e => handleChangeConfirmPassword(e)}
          />
        </HeaderPassword>
        
        <div style={{marginTop: 20, color: 'red', textAlign: 'center'}}>
          <span>{mensagem}</span>
        </div>

        <Footer>
        <FinishPurchase>
          <div>
            {passwordsAllow && passwordConfirm!== '' ? 
            <button className="btn-primary" style={{background: '#03DB9E'}} onClick={() => handleChange()}>
              Trocar Senha
            </button>:
            <button className="btn-primary" style={{background: '#03DB9E'}} onClick={() => setMensagem('As senhas não conferem')}>
              Trocar Senha
            </button>}
          </div>
        </FinishPurchase>
        </Footer>
      </>
      }

      {step === 2 && <>
        <>
          <HeaderClient style={{textAlign: 'center'}}>
            <h1><MdDone color="green" size={90}/></h1>
            <span>Senha trocada com sucesso!</span>
          </HeaderClient>
          <Footer>
          <FinishPurchase>
            <div>
              <button className="btn-primary" style={{background: '#03DB9E'}} onClick={() => handleToLogin()}>
                Fazer Login
              </button>
            </div>
          </FinishPurchase>
          </Footer>
        </>
      </>}
      </Content>
    </Container>
  );
};

export default ChangePassword;