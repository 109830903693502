import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { MdDone } from 'react-icons/md';
import { FiArrowLeft } from 'react-icons/fi';

import { useAuth } from '../../context/auth';
import api from '../../services/api';
import Select from '../../components/SelectInput';
import Modal from 'react-modal';

import {
  Container,
  Content,
  MenuContent,
  MenuItem,
  MenuItemHeader,
  HeaderClient,
  HeaderAction,
  Footer,
  ButtonOption,
  ButtonAction,
  HeaderPurchase,
  ClientDiv,
  FinishPurchase,
  SelectField,
  SelectStyled,
  InputField,
  HeaderCep,
  HeaderPassword,
  ForgotPass,
  ModalTitle,
  ModalFooter,
  ConfirmButton,
  CancelButton,
  ModalBody,
  BackButton,
} from './styles';
import { addHours, parseISO } from 'date-fns';

const Register = props => {
  const history = useHistory();
  const axios = require('axios');

  const [menu, setMenu] = useState([]);
  const [path, setPath] = useState('');
  const [phone, setCelphone] = useState('');
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordConfirm, setPasswordConfirm] = useState(null);
  const [name, setName] = useState(null);
  const [birthday, setBirthday] = useState(null);
  const [gender, setGender] = useState(null);
  const [cep, setCep] = useState(null);
  const [city, setCity] = useState(null);
  const [bairro, setBairro] = useState(null);
  const [store, setStore] = useState(null);
  const [emailClient, setEmailClient] = useState('');
  const [mensagem, setMensagem] = useState('');

  const { signIn, userToken } = useAuth();
  const [modalIsOpen, setIsOpen] = useState(false);

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setMensagemEnvio('');
  }

  const genderOptions = [
    { value: 'M', label: 'Masculino' },
    { value: 'F', label: 'Feminino' },
    { value: 'O', label: 'Outro' },
  ];

  function handleChangeCelphone(e) {
    setCelphone(e.target.value);
  }

  function handleChangePassword(e) {
    setPassword(e.target.value);
  }

  const { storeKey } = props.match.params;
  // async function getClientInfo(phone){
  //   setLoading(true);
  //   try{
  //     const response = await api.get(`/store/clients/${phone}`);
  //     setClientInfo(response.data.client);
  //   }catch(err){
  //     alert(err);
  //   }
  //   setLoading(false);
  // }

  const [mensagemEnvio, setMensagemEnvio] = useState('');

  async function handleRequestForgot() {
    try {
      const parsedPhone = phone.replace(' ', '').replace('-', '');
      const response = await api.post(
        `/public/request-change-password/${parsedPhone}`,
      );
      setMensagemEnvio('E-mail enviado com sucesso!');
    } catch (e) {
      alert('Erro ao requisitar...');
    }
  }

  async function handleToLogin() {
    setStore(null);
    history.push(`/login`);
  }

  async function handleLogin(e) {
    try {
      const parsedPhone = phone.replace(' ', '').replace('-', '');
      console.log(parsedPhone);
      await signIn({ phone: parsedPhone, password });

      history.push(`/store/${storeKey}`);
      setStore(null);
    } catch (err) {
      setMensagem(err.response.data.error);
    }
  }

  async function handleLoginRelation(e) {
    try {
      const parsedPhone = phone.replace(' ', '').replace('-', '');
      await signIn({ phone: parsedPhone, password });

      history.push(`/relate/${storeKey}`);
    } catch (e) {
      console.log(e);
      alert('Erro ao fazer login, tente novamente ...');
    }
  }

  async function handleEnter(e) {
    try {
      const parsedPhone = phone.replace(' ', '').replace('-', '');
      await signIn({ phone: parsedPhone, password });

      history.push(`/store/${storeKey}`);
    } catch (e) {
      console.log(e);
      alert('Erro ao entrar na loja...');
    }
  }

  const [mensagemBirthday, setMensagemBirthday] = useState('');
  const [mensagemName, setMensagemName] = useState('');
  const [mensagemEmail, setMensagemEmail] = useState('');
  const [mensagemPassword, setMensagemPassword] = useState('');
  const [mensagemPasswordMatch, setMensagemPasswordMatch] = useState('');
  const [mensagemCep, setMensagemCep] = useState('');
  const [mensagemGender, setMensagemGender] = useState('');

  async function handleRegister() {
    // setLoading(true);
    try {
      let register = true;
      if (!birthday || birthday.length < 10) {
        setMensagemBirthday('Insira uma data válida.');
        register = false;
      } else {
        setMensagemBirthday('');
      }
      if (!name || name.length < 6 || !name.includes(' ')) {
        setMensagemName('Insira seu nome completo.');
        register = false;
      } else {
        setMensagemName('');
      }
      if (!email || !email.includes('@') || !email.includes('.')) {
        setMensagemEmail('Insira um e-mail válido.');
        register = false;
      } else {
        setMensagemEmail('');
      }
      if (!password || password.length < 6) {
        setMensagemPassword('A senha deve ter pelo menos 6 caracteres.');
        register = false;
      } else {
        setMensagemPassword('');
      }
      if (password !== passwordConfirm) {
        setMensagemPasswordMatch('As senhas não conferem.');
        register = false;
      } else {
        setMensagemPasswordMatch('');
      }
      if (!gender) {
        setMensagemGender('Selecione seu gênero.');
        register = false;
      } else {
        setMensagemGender('');
      }
      if (!cep || cep.length < 10 || !city || city === '') {
        setMensagemCep('Insira um CEP válido.');
        register = false;
      } else {
        setMensagemCep('');
      }
      if (register) {
        let new_birthday = `${birthday[6]}${birthday[7]}${birthday[8]}${birthday[9]}-${birthday[3]}${birthday[4]}-${birthday[0]}${birthday[1]}`;
        new_birthday = parseISO(new_birthday);
        let data = {
          name,
          phone,
          email,
          password,
          passwordConfirm,
          birthday: addHours(new Date(new_birthday), 15),
          gender,
          cep,
          bairro,
          city,
        };
        await api.post(`/public/client/${storeKey}`, data);
        setStep(3);
      }
    } catch (err) {
      alert(err);
    }
    // setLoading(false);
  }

  function parseEmail(email) {
    if (email.length > 0) {
      let sufix = email.substring(email.indexOf('@') + 0);
      let mail = email.substring(0, email.indexOf('@'));
      let mail1 = mail.substring(0, 3);
      let mail2 = mail.substring(mail.length - 3, mail.length);
      let asteriscos = mail.length - 6;
      let astes = '';
      for (var i = 0; i < asteriscos; i++) {
        astes = astes + '*';
      }
      return `${mail1}${astes}${mail2}${sufix}`;
    }
  }

  async function getClient(phone) {
    setLoading(true);
    try {
      if (storeKey !== 'store') {
        const response = await api.get(`/public/clients/${phone}/${storeKey}`);
        setClient(response.data);
        if (response.data.clientEmail) {
          setEmailClient(parseEmail(response.data.clientEmail));
        }
      }
    } catch (err) {
      alert(err);
    }
    setLoading(false);
  }

  async function getStore() {
    setLoading(true);
    try {
      if (
        storeKey !== 'store' &&
        storeKey !== 'history' &&
        storeKey !== 'dashboard' &&
        storeKey !== 'login' &&
        storeKey !== 'change'
      ) {
        const response = await api.get(`/public/store/${storeKey}`);
        setStore(response.data);
        if (response.data === null) {
          history.push('/login');
        }
      }
    } catch (err) {
      alert(err);
    }
    setLoading(false);
  }

  async function getCep(cep) {
    const parsedCep = cep.replace('.', '').replace('-', '');
    const response = await api.get(`/public/cep/${parsedCep}`);
    if (response.data) {
      setCity(response.data.localidade);
      setBairro(response.data.bairro);
    }
  }

  useEffect(() => {
    if (phone.length === 15) {
      const parsedPhone = phone.replace(' ', '').replace('-', '');
      getClient(parsedPhone);
    }
  }, [phone]);

  useEffect(() => {
    if (cep && cep.length === 10) {
      getCep(cep);
    }
  }, [cep]);

  async function redirect() {
    try {
      const token = localStorage.getItem('@clube:token');
      let user = localStorage.getItem('@clube:user');
      if (storeKey === undefined) {
        history.push(`/login`);
      }
      if (
        user &&
        storeKey !== 'store' &&
        storeKey !== 'history' &&
        storeKey !== 'dashboard' &&
        storeKey !== 'login' &&
        storeKey !== 'change'
      ) {
        user = JSON.parse(user);
        const response = await api.get(
          `/public/clients/${user.phone}/${storeKey}`,
        );
        const { clientBelongsToStore } = response.data;
        if (token !== '' && clientBelongsToStore) {
          history.push(`/store/${storeKey}`);
        } else {
          history.push(`/relate/${storeKey}`);
        }
      }
    } catch (err) {
      alert(err);
    }
  }

  useEffect(() => {
    redirect();
    getStore();
  }, []);

  const [step, setStep] = useState(1);

  return (
    <Container>
      {store && userToken === '' && (
        <Content>
          <HeaderAction>
            <header>
              <h1>Clube do {store.name}</h1>
            </header>
            {step === 2 && (
              <div>
                <BackButton onClick={() => setStep(1)}>
                  <FiArrowLeft />
                  Mudar número
                </BackButton>
              </div>
            )}
          </HeaderAction>

          {step === 1 && (
            <>
              <HeaderClient>
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={store.avatar && store.avatar.url}
                    style={{
                      width: 120,
                      height: 120,
                      borderRadius: '50%',
                      marginBottom: 10,
                    }}
                  />
                  <p>
                    Para acessar o Clube do {store.name}, insira o celular
                    informado
                  </p>
                </div>
                <InputMask
                  mask="(99) 99999-9999"
                  maskChar=""
                  value={phone}
                  onChange={e => handleChangeCelphone(e)}
                />
              </HeaderClient>

              {phone.length < 15 && (
                <div style={{ textAlign: 'center' }}>
                  <ForgotPass
                    onClick={() => {
                      handleToLogin();
                    }}
                    style={{ color: '#35827D' }}
                  >
                    ou faça o seu login
                  </ForgotPass>
                </div>
              )}

              {client && phone.length === 15 && (
                <div style={{ width: '100%', textAlign: 'center' }}>
                  {client.clientExist && !client.clientBelongsToStore && (
                    <>
                      <HeaderPassword>
                        <div style={{ textAlign: 'center' }}>
                          Digite sua senha
                        </div>
                        <InputMask
                          type="password"
                          onChange={e => handleChangePassword(e)}
                        />
                      </HeaderPassword>
                      <span style={{ color: 'green' }}>
                        Número já cadastrado, faça login para participar desse
                        clube e ganhar{' '}
                        {!isNaN(client.totalClientCashback)
                          ? `R$${client.totalClientCashback.toLocaleString(
                              'pt-BR',
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            )}`
                          : `${client.totalClientPoints} pontos`}
                        .
                      </span>

                      <Footer>
                        <FinishPurchase>
                          <div>
                            <button
                              className="btn-primary"
                              onClick={() => handleLoginRelation()}
                            >
                              Login
                            </button>
                          </div>
                        </FinishPurchase>
                      </Footer>
                    </>
                  )}
                  {!client.clientExist && (
                    <>
                      <span style={{ color: 'green' }}>
                        Para ganhar{' '}
                        {!isNaN(client.totalClientCashback)
                          ? `R$${client.totalClientCashback.toLocaleString(
                              'pt-BR',
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            )}`
                          : `${client.totalClientPoints} pontos`}
                        , finalize seu cadastro!
                      </span>
                    </>
                  )}
                  {client.clientExist && client.clientBelongsToStore && (
                    <>
                      <HeaderPassword>
                        <div style={{ textAlign: 'center' }}>
                          Digite sua senha
                        </div>
                        <InputMask
                          type="password"
                          onChange={e => handleChangePassword(e)}
                        />
                      </HeaderPassword>

                      <span style={{ color: '#235753' }}>
                        Você já participa desse clube, faça login
                      </span>

                      <ForgotPass
                        onClick={() => {
                          openModal();
                        }}
                      >
                        Esqueci minha senha
                      </ForgotPass>

                      <div
                        style={{
                          color: 'red',
                          textAlign: 'center',
                          marginTop: 10,
                        }}
                      >
                        <span>{mensagem}</span>
                      </div>

                      <Footer>
                        <FinishPurchase>
                          <div>
                            <button
                              className="btn-primary"
                              style={{ background: '#03DB9E' }}
                              onClick={() => handleLogin()}
                            >
                              Login
                            </button>
                          </div>
                        </FinishPurchase>
                      </Footer>
                    </>
                  )}
                </div>
              )}

              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Esqueci minha senha"
              >
                <ModalTitle>
                  <p>Esqueci minha senha</p>
                </ModalTitle>
                <ModalBody>
                  {mensagemEnvio === '' ? (
                    <>
                      <span style={{ textAlign: 'center' }}>
                        Será enviado um link de redefinição via e-mail para o
                        endereço cadastrado no Clube do Cliente de telefone{' '}
                        {phone}.
                      </span>
                      <br />
                      <span style={{ textAlign: 'center' }}>{emailClient}</span>
                    </>
                  ) : (
                    <span style={{ color: 'green' }}>{mensagemEnvio}</span>
                  )}
                </ModalBody>
                <ModalFooter>
                  {mensagemEnvio === '' && (
                    <ConfirmButton onClick={() => handleRequestForgot()}>
                      Enviar E-mail
                    </ConfirmButton>
                  )}
                  <CancelButton onClick={closeModal}>Cancelar</CancelButton>
                </ModalFooter>
              </Modal>

              {client && !client.clientExist && phone.length === 15 && (
                <Footer>
                  <FinishPurchase>
                    <div>
                      <button
                        className="btn-primary"
                        style={{ background: '#03DB9E' }}
                        onClick={() => setStep(2)}
                      >
                        Continuar Cadastro
                      </button>
                    </div>
                  </FinishPurchase>
                </Footer>
              )}
            </>
          )}

          {step === 2 && (
            <>
              <div
                style={{
                  width: '100%',
                  marginLeft: 3,
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 10,
                }}
              >
                <div>
                  <img
                    src={store.avatar && store.avatar.url}
                    style={{
                      width: 100,
                      height: 100,
                      borderRadius: '50%',
                      marginBottom: 10,
                    }}
                  />
                </div>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                  <h2
                    style={{
                      color: '#434C4C',
                      fontFamily: 'Montserrat, sans-serif',
                    }}
                  >
                    {phone}
                  </h2>
                  <h4 style={{ color: '#03B582' }}>Seja bem-vindo!</h4>
                </div>
              </div>

              <InputField>
                <p>Nome Completo</p>
                <input
                  type="text"
                  placeholder="Digite seu nome completo"
                  name="name"
                  id="name"
                  value={name}
                  onChange={e => {
                    setName(e.target.value);
                  }}
                  autoComplete="off"
                />
              </InputField>

              <div
                style={{
                  color: 'red',
                  textAlign: 'center',
                  marginTop: 10,
                  fontSize: 13,
                }}
              >
                <span>{mensagemName}</span>
              </div>

              <InputField>
                <p>E-mail</p>
                <input
                  type="email"
                  placeholder="Digite seu e-mail"
                  name="email"
                  id="email"
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                  }}
                  autoComplete="off"
                />
              </InputField>

              <div
                style={{
                  color: 'red',
                  textAlign: 'center',
                  marginTop: 10,
                  fontSize: 13,
                }}
              >
                <span>{mensagemEmail}</span>
              </div>

              <InputField>
                <p>Senha</p>
                <input
                  type="password"
                  placeholder="Digite uma senha"
                  name="password"
                  id="password"
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value);
                  }}
                  autoComplete="off"
                />
              </InputField>

              <div
                style={{
                  color: 'red',
                  textAlign: 'center',
                  marginTop: 10,
                  fontSize: 13,
                }}
              >
                <span>{mensagemPassword}</span>
              </div>

              <InputField>
                <p>Confirmar Senha</p>
                <input
                  type="password"
                  placeholder="Confirme a senha"
                  name="passwordConfirm"
                  id="passwordConfirm"
                  value={passwordConfirm}
                  onChange={e => {
                    setPasswordConfirm(e.target.value);
                  }}
                  autoComplete="off"
                />
              </InputField>

              <div
                style={{
                  color: 'red',
                  textAlign: 'center',
                  marginTop: 10,
                  fontSize: 13,
                }}
              >
                <span>{mensagemPasswordMatch}</span>
              </div>

              <InputField>
                <p>Data de Nascimento</p>
                <InputMask
                  mask="99/99/9999"
                  maskChar=""
                  placeholder="__/__/____"
                  type="text"
                  name="birthday"
                  id="birthday"
                  value={birthday}
                  onChange={e => {
                    setBirthday(e.target.value);
                  }}
                  autoComplete="off"
                />
              </InputField>

              <div
                style={{
                  color: 'red',
                  textAlign: 'center',
                  marginTop: 10,
                  fontSize: 13,
                }}
              >
                <span>{mensagemBirthday}</span>
              </div>

              <SelectField>
                <p>Sexo</p>
                <SelectStyled
                  name="gender"
                  options={genderOptions}
                  placeholder="Selecione seu sexo"
                  onChange={e => {
                    setGender(e.value);
                  }}
                  noOptionsMessage={() => 'nada encontrado ...'}
                />
              </SelectField>

              <div
                style={{
                  color: 'red',
                  textAlign: 'center',
                  marginTop: 10,
                  fontSize: 13,
                }}
              >
                <span>{mensagemGender}</span>
              </div>

              <HeaderCep>
                <p>CEP</p>
                <InputMask
                  mask="99.999-999"
                  maskChar=""
                  placeholder="xx.xxx-xxx"
                  value={cep}
                  onChange={e => setCep(e.target.value)}
                />
              </HeaderCep>

              <div
                style={{
                  color: 'red',
                  textAlign: 'center',
                  marginTop: 10,
                  fontSize: 13,
                }}
              >
                <span>{mensagemCep}</span>
              </div>

              <InputField>
                <p>Cidade</p>
                <input
                  type="text"
                  placeholder="Digite o CEP"
                  name="city"
                  id="city"
                  value={city}
                  disabled
                  onChange={e => {
                    setCity(e.target.value);
                  }}
                  autoComplete="off"
                />
              </InputField>

              <InputField>
                <p>Bairro</p>
                <input
                  type="text"
                  placeholder="Digite o CEP"
                  name="bairro"
                  id="bairro"
                  value={bairro}
                  onChange={e => {
                    setBairro(e.target.value);
                  }}
                  disabled
                  autoComplete="off"
                />
              </InputField>

              <Footer>
                <FinishPurchase>
                  <div>
                    <button
                      className="btn-primary"
                      style={{ background: '#03DB9E' }}
                      onClick={() => handleRegister()}
                    >
                      Concluir Cadastro
                    </button>
                  </div>
                </FinishPurchase>
              </Footer>
            </>
          )}
          {step === 3 && (
            <>
              <HeaderClient style={{ textAlign: 'center' }}>
                <h1>
                  <MdDone color="green" size={90} />
                </h1>
                <span>Cadastro realizado com sucesso!</span>
              </HeaderClient>
              <Footer>
                <FinishPurchase>
                  <div>
                    <button
                      className="btn-primary"
                      style={{ background: '#03DB9E' }}
                      onClick={() => handleEnter()}
                    >
                      Entrar no Clube
                    </button>
                  </div>
                </FinishPurchase>
              </Footer>
            </>
          )}
        </Content>
      )}
    </Container>
  );
};

export default Register;
