import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';

export default function formatTime(date) {
  if (!(date instanceof Date)) {
    return format(parseISO(date), 'HH:mm', {
      locale: pt,
    });
  }

  return format(date, 'HH:mm', {
    locale: pt,
  });
}
