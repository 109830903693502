import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { FaBars } from 'react-icons/fa';
import { AiOutlineCheck, AiOutlinePlusCircle } from 'react-icons/ai';

import { useAuth } from '../../context/auth';

import api from '../../services/api';
import PromotionComponent from '../../components/PromotionComponent';
import RatingComponent from '../../components/RatingComponent';

import {
  Container,
  Content,
  HeaderAction,
  ContentDiv,
  ModalTitle,
  ButtonNone,
  CancelButton,
  ModalBody,
  ModalItem,
} from './styles';

import logo from '../../assets/images/fav.png';

const Store = props => {
  const { user, signOut } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState([]);
  const [cashback, setCashback] = useState(null);
  const [points, setPoints] = useState(null);
  const [settings, setSettings] = useState(null);
  const [promotions, setPromotions] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [rescues, setRescues] = useState([]);
  const [cashbackModel, setCashbackModel] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [pendingRate, setPendingRate] = useState([]);
  const [purchaseRate, setPurchaseRate] = useState(null);
  const [modalInfoIsOpen, setInfoIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModalInfo() {
    setInfoIsOpen(true);
  }

  function closeModalInfo() {
    setInfoIsOpen(false);
  }

  function logOut() {
    signOut();
    history.push('/login');
  }

  function toDashboard() {
    history.push(`/dashboard`);
  }

  const { storeKey } = props.match.params;

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  function titleize(text) {
    var words = text.toLowerCase().split(' ');
    for (var a = 0; a < words.length; a++) {
      var w = words[a];
      words[a] = w[0].toUpperCase() + w.slice(1);
    }
    return words.join(' ');
  }

  async function getInfo() {
    try {
      const response = await api.get(`/client/store/${storeKey}`);
      setStore(response.data);
      if (response.data.clients[0].cashback) {
        setCashback(response.data.clients[0].cashback);
      } else {
        setPoints(response.data.clients[0].points);
      }
      if (response.data.settings[0]) {
        setSettings(response.data.settings[0]);
      }
      if (response.data.settings[0].model === 'points') {
        setCashbackModel(false);
      }
      setPromotions(response.data.promotions);
      setPurchases(response.data.purchases);
      setRescues(response.data.rescues);
      setPendingRate(response.data.pendingRated);
      if (response.data.pendingRated.length > 0) {
        setPurchaseRate(response.data.pendingRated[0]);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        history.push('/login', { from: 'store-page' });
        alert('Você precisa fazer login primeiro');
      } else {
        alert(err);
      }
    }
  }

  useEffect(() => {
    getInfo();
  }, []);

  function getPurchaseToRate(id) {
    if (pendingRate.length > 0) {
      const purch = pendingRate.filter(purchase => purchase.id !== id);
      setPendingRate(purch);
      setPurchaseRate(purch[0]);
    } else {
      setPurchaseRate(null);
    }
  }

  return (
    <Container>
      <Content>
        <HeaderAction>
          <header>
            <div
              style={{ display: 'flex', flexDirection: 'row', marginLeft: -10 }}
            >
              {/* <img src={logo} height="55" style={{marginRight: 10}}/>
              <div style={{flexDirection: 'column', margin: 'auto', textAlign: 'center'}}>
                <h1 style={{fontSize: 18}}>Clube do {store.name}</h1>
                 <div>Bem vindo, {titleize(user.name.substring(0, 16))}{user.name.length > 17 && '...'}!</div> 
              </div> */}
              <h1 style={{ fontSize: 21 }}>Clube do {store.name}</h1>
            </div>
            <button
              onClick={() => {
                openModal();
              }}
            >
              <FaBars />
            </button>
          </header>
        </HeaderAction>

        <ContentDiv>
          <div style={{ width: '30%' }}>
            <img src={store.avatar ? store.avatar.url : null} />
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '70%' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 10,
                marginTop: 10,
              }}
            >
              {/* <div style={{width: '33%', display: 'flex', flexDirection: 'column'}}>
                <h3 style={{color: 'green', marginTop: 3, textAlign: 'center'}}>
                  {store.members}
                </h3>
                <span style={{textAlign: 'center'}}>
                  Membros
                </span>
              </div> */}

              <div
                style={{
                  width: '34%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <h3
                  style={{ color: 'green', marginTop: 3, textAlign: 'center' }}
                >
                  {cashback ? `R$${cashback}` : `${points}`}
                </h3>
                <span style={{ textAlign: 'center' }}>
                  {cashback ? `Saldo` : `Pontos`}
                </span>
              </div>

              <div
                style={{
                  width: '33%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <h3
                  style={{ color: 'green', marginTop: 3, textAlign: 'center' }}
                >
                  {purchases.length}
                </h3>
                <span style={{ textAlign: 'center' }}>Compras</span>
              </div>

              <div
                style={{
                  width: '33%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <h3
                  style={{ color: 'green', marginTop: 3, textAlign: 'center' }}
                >
                  {rescues.length}
                </h3>
                <span style={{ textAlign: 'center' }}>Resgates</span>
              </div>
            </div>
            <div
              style={{
                background: '#03DB9E',
                borderRadius: '5px',
                marginTop: 20,
                height: 30,
                textAlign: 'center',
                color: '#000000',
                marginLeft: 15,
              }}
            >
              <div style={{ marginTop: 5 }}>
                <AiOutlineCheck style={{ marginRight: 5 }} />
                Participando
              </div>
            </div>
          </div>
        </ContentDiv>
        <div style={{ margin: 5 }}>
          <div>Bem vindo, {user?.name}!</div>
        </div>

        <div
          style={{
            width: '98%',
            borderTop: '1px solid #C8C2BC',
            margin: 'auto',
          }}
        ></div>

        {purchaseRate && (
          <div>
            <RatingComponent
              store={store}
              purchase={purchaseRate}
              getPurchaseToRate={getPurchaseToRate}
            />
          </div>
        )}

        {promotions.length > 0 && (
          <ContentDiv style={{ flexDirection: 'column' }}>
            <h3>Ofertas</h3>
            {promotions.map(promotion => (
              <PromotionComponent promotion={promotion} points={points} />
            ))}
            <span
              style={{
                margin: 10,
                marginTop: 15,
                textAlign: 'center',
                color: '#877C6E',
              }}
            >
              Para resgatar ofertas é só fazer uma compra no local!
            </span>
          </ContentDiv>
        )}

        {settings && (
          <button
            style={{
              height: 50,
              background: 'transparent',
              border: 'none',
              textAlign: 'left',
              fontSize: 20,
              marginLeft: 10,
              color: '#38332E',
            }}
            onClick={() => openModalInfo()}
          >
            <AiOutlinePlusCircle /> Regras de Resgate
          </button>
        )}

        {/* {
          purchases.length > 0 &&
          <ContentDiv style={{flexDirection: 'column'}}>
            <h2>Compras</h2>
            {purchases.map(purchase => 
            <div style={{display: 'flex', flexDirection: 'row', marginTop: 15}}>
              <h3 style={{color: '#D74753', width: '33%'}}>R${purchase.total}</h3>
              <div style={{color: 'green', marginTop: 3, textAlign: 'left', float: 'right', width: '33%'}}>
                {purchase.points ? `${purchase.points} pontos` : `R$${purchase.cashback}`}
              </div>
              <div style={{color: '#70675C', marginTop: 3, textAlign: 'right', float: 'right', width: '33%'}}>
                {formatCompleteDate(purchase.createdAt)}
              </div>
            </div>
            )}
          </ContentDiv>
        } */}
        <div
          style={{
            margin: 'auto',
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <img src={logo} height="50" width="50" style={{ margin: 'auto' }} />
          <p style={{ fontSize: 13 }}>Powered By Clube do Cliente ©</p>
        </div>
      </Content>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '80%' }}>{user?.name}</div>
            <div style={{ width: '20%', float: 'right', textAlign: 'right' }}>
              <CancelButton onClick={closeModal}>x</CancelButton>
            </div>
          </div>
        </ModalTitle>
        <ModalBody>
          <ModalItem>
            <div style={{ margin: 'auto', textAlign: 'center' }}>
              <div>
                <ButtonNone onClick={() => toDashboard()}>
                  Minhas Lojas
                </ButtonNone>
              </div>
              <div>
                <ButtonNone onClick={() => history.push(`/history`)}>
                  Histórico de Compras
                </ButtonNone>
              </div>
              <div>
                <ButtonNone style={{ color: 'red' }} onClick={() => logOut()}>
                  Sair
                </ButtonNone>
              </div>
            </div>
          </ModalItem>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalInfoIsOpen}
        onRequestClose={closeModalInfo}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '80%' }}>
              <h3>Observações do Estabelecimento</h3>
            </div>
            <div style={{ width: '20%', float: 'right', textAlign: 'right' }}>
              <CancelButton onClick={closeModalInfo}>x</CancelButton>
            </div>
          </div>
        </ModalTitle>
        <ModalBody>
          {settings && (
            <ContentDiv style={{ flexDirection: 'column' }}>
              <span style={{ margin: 5 }}>
                Resgate Mínimo:{' '}
                {cashbackModel
                  ? `R$${settings.minimo}`
                  : `${settings.minimo} ponto(s)`}
              </span>
              <span style={{ margin: 5 }}>
                {cashbackModel
                  ? `Ganhe ${settings.percent}% de cashback a cada compra!`
                  : `Ganhe 1 ponto a cada R$${parseFloat(
                      settings.points,
                    ).toFixed(2)} em compra!`}
              </span>
              <span style={{ margin: 5 }}>{settings.obs}</span>
            </ContentDiv>
          )}
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default Store;
