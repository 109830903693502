import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import Modal from 'react-modal';

import { useAuth } from '../../context/auth';

import StoreComponent from '../../components/StoreComponent';

import api from '../../services/api';

import {
  Container,
  Content,
  HeaderAction,
  ButtonNone,
  ModalTitle,
  CancelButton,
  ModalBody,
  ModalItem
} from './styles';

import logo from "../../assets/images/fav.png";

const Dashboard = props => {
  const { user, signOut } = useAuth();
  const history = useHistory();
  const [path, setPath] = useState('');
  const [data, setData] = useState([]);
  const [client, setClient] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  function logOut() {
    signOut();
    history.push('/login');
  }

  function toDashboard() {
    history.push(`/dashboard`);
  }

  function toStore(id) {
    history.push(`/store/${id}`);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  
  async function getInfo(){
    try{
      const response = await api.get(`/client`);
      setClient(response.data.client);
      setData(response.data);
      const stores = response.data.client.client.map(store => store);
      setStores(stores);
      console.log('data', response.data);
    }catch(err){
      alert(err);
    }
  }

  useEffect(() => {
    getInfo();
  }, []);

  function titleize(text) {
    var words = text.toLowerCase().split(" ");
    for (var a = 0; a < words.length; a++) {
        var w = words[a];
        words[a] = w[0].toUpperCase() + w.slice(1);
    }
    return words.join(" ");
  }

  return (
    <Container>
      <Content>
        <HeaderAction>
          <header>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <img src={logo} width="30" heigth="30" style={{marginRight: 10, marginLeft: -10}}/>
              <h1>Clube do Cliente</h1>
            </div>
            <button
              onClick={() => {
                openModal();
              }}
            >
              <FaBars />
            </button>
          </header>
          <div>Bem vindo, {titleize(user.name.substring(0, 16))}{user.name.length > 17 && '...'}!</div>
        </HeaderAction>

        <div style={{paddingTop: 40}}>
          {stores.map((store) => 
          <ButtonNone onClick={() => toStore(store.store.key)}>
            <StoreComponent store={store} client={client}/>
          </ButtonNone>
          )}
        </div>
      </Content>

      <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Descrição do Item"
        >
          <ModalTitle>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div style={{width: '80%'}}>
              {user.name}
              </div>
              <div style={{width: '20%', float: 'right', textAlign: 'right'}}>
                <CancelButton onClick={closeModal}>x</CancelButton>
              </div>
            </div>
          </ModalTitle>
          <ModalBody>
            <ModalItem>
              <div style={{margin: 'auto', textAlign: 'center'}}>
                {/* <div>
                  <ButtonNone onClick={() => toDashboard()}>
                    Minhas Lojas
                  </ButtonNone>
                </div> */}
                 <div>
                  <ButtonNone onClick={() => history.push(`/history`)}>
                    Histórico de Compras
                  </ButtonNone>
                </div>
                <div>
                  <ButtonNone style={{color: 'red'}} onClick={() => logOut()}>
                    Sair
                  </ButtonNone>
                </div>
              </div>
            </ModalItem>
          </ModalBody>
        </Modal>
    </Container>
  );
};

export default Dashboard;
