import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import Modal from 'react-modal';

import { TiDocumentText } from 'react-icons/ti';
import { FaBars } from 'react-icons/fa';

// import { FiCalendar, FiCheckSquare, FiGrid } from 'react-icons/fi';
import { MdAddShoppingCart, MdHistory } from 'react-icons/md';

import { useAuth } from '../../context/auth';
import PurchaseComponent from '../../components/PurchaseComponent';
import api from '../../services/api';

import {
  Container,
  Content,
  HeaderAction,
  ModalTitle,
  ButtonNone,
  CancelButton,
  ModalBody,
  ModalItem
} from './styles';

const History = props => {
  const { user, signOut } = useAuth();
  const history = useHistory();
  const [purchases, setPurchases] = useState([]);
  const [purchasesToShow, setPurchasesToShow] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const settings = user && user.settings ? user.settings[0] : null;
  
  function logOut() {
    signOut();
    history.push('/login');
  }

  function toDashboard() {
    history.push(`/dashboard`);
  }

  async function getPurchases(){
    // setLoading(true);
    try{
      const response = await api.get(`/client/purchases`);
      setPurchases(response.data.purchases);
    }catch(err){
      alert(err);
    }
    // setLoading(false);
  }

  useEffect(() => {
    getPurchases();
  }, []);

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  function titleize(text) {
    var words = text.toLowerCase().split(" ");
    for (var a = 0; a < words.length; a++) {
        var w = words[a];
        words[a] = w[0].toUpperCase() + w.slice(1);
    }
    return words.join(" ");
  }

  return (
    <Container>
      <Content>
      <HeaderAction>
          <header>
            <h1>Clube do Cliente</h1>
            <button
              onClick={() => {
                openModal();
              }}
            >
              <FaBars />
            </button>
          </header>
          <div>Bem vindo, {titleize(user.name.substring(0, 16))}{user.name.length > 17 && '...'}!</div>
        </HeaderAction>
        
        <div style={{marginTop: 20}}>
          {purchases.map((purchase) => 
            <PurchaseComponent purchase={purchase} settings={settings}/>
          )}
        </div>

      </Content>

      <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Descrição do Item"
        >
          <ModalTitle>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div style={{width: '80%'}}>
              {user.name}
              </div>
              <div style={{width: '20%', float: 'right', textAlign: 'right'}}>
                <CancelButton onClick={closeModal}>x</CancelButton>
              </div>
            </div>
          </ModalTitle>
          <ModalBody>
            <ModalItem>
              <div style={{margin: 'auto', textAlign: 'center'}}>
                <div>
                  <ButtonNone onClick={() => toDashboard()}>
                    Minhas Lojas
                  </ButtonNone>
                </div>
                <div>
                  <ButtonNone onClick={() => history.push(`/history`)}>
                    Histórico de Compras
                  </ButtonNone>
                </div>
                <div>
                  <ButtonNone style={{color: 'red'}} onClick={() => logOut()}>
                    Sair
                  </ButtonNone>
                </div>
              </div>
            </ModalItem>
          </ModalBody>
        </Modal>

    </Container>
  );
};

export default History;
