import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Select from 'react-select';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;


export const InputField = styled.div`
  p {
    font-size: 14px;
    font-weight: normal;
  }
  margin-top: 16px;
  margin-right: 4px;
  
  input {
    border-radius: 5px;
    border: 1px solid #33333330;
    padding: 5px 10px;
    margin-top: 5px;
    width: 100%;
  }
`;

export const HeaderCep = styled.div`
  p {
    font-size: 14px;
    font-weight: normal;
  }
  margin-top: 16px;
  margin-right: 4px;

  input {
    order-radius: 6px;
    border: 1px solid #33333330;
    padding: 5px 10px;
    margin-top: 5px;
    width: 100%;

    &::placeholder {
      margin-top: 40px;
    }
  }
`;


export const HeaderClient = styled.div`
  width: 100%;
  margin-top: 20px;
  padding-right: 10px;
  background: #fff;
  // border-bottom: 1px solid #f8f8f8;

  input {
    width: 90%;
    margin: 20px;
    height: 60px;
    font-size: 30px;
    color: #03B582;
    background: transparent;
    border-radius: 7px;
    border: 1px solid #03DB9E;
    margin-top: 10px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;

    &::placeholder {
      text-align: center;
      color: #03B582;
      margin-top: 40px;
    }
  }
`;

export const HeaderPassword = styled.div`
  width: 100%;
  margin-top: 30px;
  padding-right: 10px;
  background: #fff;
  // border-bottom: 1px solid #f8f8f8;

  input {
    width: 90%;
    margin: 20px;
    height: 60px;
    font-size: 30px;
    color: #03B582;
    background: transparent;
    border-radius: 7px;
    border: 1px solid #03DB9E;
    margin-top: 10px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;

    &::placeholder {
      text-align: center;
      color: #03B582;
      margin-top: 40px;
    }
  }
`;

export const FinishPurchase = styled.div`
  width: 100%;
  background: #fff;
  border-top: 1px solid #f8f8f8;
  float: bottom;
  padding-top: 15px;
  padding-right: 10px;

  > div {
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`;

export const ClientDiv = styled.div`
  width: 90%;
  top: 0;
  margin: auto;
  height: 140px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 14px;
  margin-top: 10px;
  border-radius: 7px;
  padding-bottom: 14px;
  border-bottom: 1px solid #DCDBD0;
  background-color: #DCDBD0;
  font-family: 'Montserrat', sans-serif;;

  button {
    left: 10px;
    width: 150px;
    height: 32px;
    background: #77dd77;
    color: #fff;
    border-radius: 20px;
    border: none;
    margin-top: 8px;
  }
`;

export const HeaderPurchase = styled.div`
  width: 100%;
  padding-right: 10px;
  padding-top: 0px;
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
  background: #fff;
  border-bottom: 1px solid #f8f8f8;

  input {
    width: 80%;
    margin: 20px;
    height: 60px;
    font-size: 30px;
    color: #678958;
    background: transparent;
    border-radius: 7px;
    border: 1px solid #678958;
    margin-top: 10px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;

    &::placeholder {
      text-align: left;
      color: #678958;
      margin-top: 40px;
    }
  }
`;


export const SelectField = styled.div`
  width: 100%;
  margin: auto;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  p {
    font-size: 14px;
    text-align: left;
    margin-bottom: 4px;
  }
  // & + div {
  //   margin-top: 24px;
  // }
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  & + & {
    margin-top: 16px;
  }
`;

export const HeaderAction = styled.div`
  position: fixed;
  top: 0;
  padding-top: 14px;
  padding-right: 10px;

  background: #fff;
  width: 100%;
  max-width: 410px;

  div {
    padding-left: 10px;
  }

  > button {
    position: absolute;
    left: 10px;
    width: 150px;
    height: 32px;
    background: #77dd77;
    color: #fff;
    border-radius: 20px;
    border: none;
    margin-top: 8px;
  }

  header {
    display: flex;
    align-items: left;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;

    h1 {
      font-size: 24px;
      font-weight: bold;
    }
    > div {
      font-size: 14px;
    }

    button {
      width: 60px;
      height: 28px;
      color: #fff;
      background: #fb4a20;
      border-radius: 20px;
      border: none;
      margin-top: 10px;
    }
  }
  nav {
    margin-top: 64px;
    height: 50px;
    display: inline-flex;
    width: 100%;
    max-width: 410px;
    .scroll-container {
      display: flex;
      align-content: center;
      padding-bottom: 15px;
      li {
        list-style: none;
        display: flex;
        align-items: center;
        .styled-links {
          cursor: pointer;
          color: #333;
          font-size: 14px;
          font-weight: bold;
          text-decoration: none;
          display: flex;
          align-items: center;
          width: 150px;
          height: 30px;
          margin-right: 8px;
          border: 1px solid rgba(251, 74, 32, 1);
          color: rgba(251, 74, 32, 4);
          justify-content: center;
          border-radius: 25px;
          &:hover {
            background: linear-gradient(
              144deg,
              rgba(251, 74, 32, 1) 28%,
              rgba(250, 5, 97, 1) 100%
            );
            color: #fff;
          }
        }
      }
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 414px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  padding-bottom: 85px;
  margin-top: 65px;
`;

export const Footer = styled.footer`
  width: 100%;
  max-width: 410px;
  height: 75px;
  background: #fff;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #33333310;
`;

export const ButtonGoToAccount = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  color: #333;
  svg {
    margin-bottom: 4px;
  }
`;

export const ForgotPass = styled.button`
  display: flex;
  margin: auto;
  margin-top: 10px;
  border: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  text-decoration: none;
  color: blue;
  background: transparent;
`;

export const MenuContent = styled.div`
  background: #fff;
  border-radius: 7px;
  margin-top: 120px;
`;

export const MenuItem = styled.li`
  list-style: none;
  padding: 5px;
`;

export const Badge = styled.div`
  font-size: 10px;
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  width: 75px;
  height: 25px;
  color: #fff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  text-align: center;
  font-weight: bold;
`;

export const AddToCart = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

export const ButtonGoToCart = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  border-radius: 50%;
  margin-bottom: 25px;
  position: relative;
  cursor: pointer;
`;

export const CountBadge = styled.div`
  position: absolute;
  top: 0;
  left: 45px;
  font-size: 12px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  background: #fff;
  width: 22px;
  height: 22px;
  color: rgba(251, 74, 32, 1);
  border: 1px solid rgba(251, 74, 32, 1);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f8f8;
  border-radius: 7px;
  padding: 5px;
  div {
    font-size: 12px;
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
      display: flex;
      align-items: center;
      > svg {
        margin-right: 5px;
      }
    }
    svg {
      margin-left: 5px;
    }
  }
  strong {
    font-weight: 700;
  }
`;

export const MenuItemBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  & + div {
    padding-top: 20px;
    border-top: 1px solid #33333310;
  }
`;

export const MenuItemDescription = styled.div`
  width: 110px;
  margin-left: 8px;
  font-size: 14px;
  color: #333;
  strong {
    font-weight: 400;
  }
`;

export const ModalDescription = styled.div`
  width: 200px;
  position: relative;

  svg {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const ButtonAction = styled.div`
  width: 100%;
  max-width: 410px;
  height: 75px;
  max-width: 410px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const ButtonOption = styled(Link)`
  width: 74px;
  text-decoration: none;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #333;

  position: relative;
  font-size: 12px;

  cursor: pointer;

  svg {
    margin-bottom: 4px;

    ${item =>
      item.path === '/orderspending' &&
      css`
        stroke: #fff;
      `}
  }

  ${item =>
    item.path === '/orderspending' &&
    css`
      background: linear-gradient(
        144deg,
        rgba(251, 74, 32, 1) 28%,
        rgba(250, 5, 97, 1) 100%
      );
      border-radius: 7px;
      color: #fff;
    `}
`;

export const MenuItemTableNumber = styled.p`
  width: 35px;
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
`;

export const MenuItemBuyer = styled.p`
  width: 45px;
  font-size: 14px;
`;

export const MenuItemCheck = styled.p`
  width: 35px;

  svg {
    margin-left: 12px;
  }
`;

export const MenuItemOrderTime = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
`;


