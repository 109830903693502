import React from 'react';


const PromotionComponent = ({promotion, points}) => {
  
  const available = points >= parseFloat(promotion.points) ? true : false;

  let styleDiv = {
    display: 'flex', flexDirection: 'row', marginTop: 10
  };

  if(!available){
    styleDiv.opacity = 0.3;
  }

  return (
    <div style={styleDiv}>
      <img src={promotion.avatar && promotion.avatar.url} style={{width: 70, height: 70, borderRadius: '50%' }} />
      <div style={{flexDirection: 'column', marginLeft: 5}}>
        <h3 style={{color: '#5A5249', marginTop: 3}}>{promotion.name}</h3>
        <div style={{color: 'green', marginTop: 6 }}>
          {promotion.points} pontos
        </div>
      </div>
    </div>
  );
};

export default PromotionComponent;
