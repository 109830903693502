import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../context/auth';

import { Container, Title, Content, Footer, FinishPurchase } from './styles';
import api from '../../services/api';

const Relate = props => {
  const history = useHistory();
  const [store, setStore] = useState([]);
  const { storeKey } = props.match.params;

  async function handleSubmit(e) {
    try {
      const response = await api.post('/client/create-relation', {
        key: storeKey,
      });
      history.push(`/store/${storeKey}`);
    } catch (err) {
      console.log(err.response?.data);
      if (err.response?.data?.message) {
        alert(err.response.data.message);
      } else {
        alert('Erro ao relacionar cliente');
      }
    }
  }

  async function getInfo(e) {
    try {
      const response = await api.get(`/client/store/${storeKey}`);
      setStore(response.data);
      console.log(response.data);
    } catch (e) {
      console.log('Error get info: ', e?.response);
      if (e.response?.status === 401) {
        alert('Cliente precisa realizar o login primeiro');
        history.push('/login');
      } else {
        alert('Erro ao carregar informações...');
      }
    }
  }

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <Container>
      <Content>
        <Title>
          Clube {store.name}
          <img
            src={store.avatar ? store.avatar.url : null}
            style={{ width: 150, height: 150, marginTop: 10 }}
          />
        </Title>
        <Footer>
          <FinishPurchase>
            <div>
              <button className="btn-primary" onClick={() => handleSubmit()}>
                Participar
              </button>
            </div>
          </FinishPurchase>
        </Footer>
      </Content>
    </Container>
  );
};

export default Relate;
