import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Rating from 'react-rating';
import { FiStar } from 'react-icons/fi';

import api from '../../services/api';
import formatDate from '../../services/formatDate';
import formatTime from '../../services/formatTime';
import { useAuth } from '../../context/auth';

import { Container, Content, Message, Observations, RatingButton, RatingButtonSelected } from './styles';

const RatingComponent = ({store, purchase, getPurchaseToRate}) => {
  const history = useHistory();
  const { sessionKey } = useAuth();
  const [selectedItem, setSelectedItem] = useState([]);
  const [clientRating, setClientRating] = useState(0);

  async function handleSubmit() {
    try {
      await api.post('/client/ratings', {
        stars: clientRating,
        ratings: selectedItem,
        store_id: store.id,
        purchase_id: purchase.id
      });
      getPurchaseToRate(purchase.id);
    } catch (error) {
      console.log(error);
    }
  }

  function handleSelectRating(rating) {
    const findItem = selectedItem.findIndex(item => item === rating);

    if (findItem >= 0) {
      const filteredItems = selectedItem.filter(item => item !== rating);

      setSelectedItem(filteredItems);
    } else {
      setSelectedItem([...selectedItem, rating]);
    }
  }

  function handleRate(rate) {
    setClientRating(rate);
  }

  const compliments = [
    'Atendimento',
    'Limpeza',
    'Preço',
    'Produto',
    'Tempo',
    'Variedade'
  ];

  useEffect(() => {
    setClientRating(-1);
    setSelectedItem([]);
  }, [purchase]);

  return (
    <Container>
      {clientRating === -1 &&
        <Message>
          {/* <div style={{paddingRight: 10, paddingBottom: 10, width: '100%', textAlign: 'right'}}>
            x
          </div> */}
          <h4>Avalie sua visita do dia {formatDate(purchase.createdAt)} as {formatTime(purchase.createdAt)}</h4>
          <p>
            De 0 a 10, o quanto você recomendaria o {store.name} a um amigo ou familiar?
          </p>
          {/* <p>
            <Rating
              emptySymbol={<FiStar size={28} color="#33333330" />}
              fullSymbol={<FiStar size={28} color="#fb4a20" />}
              onChange={rate => {
                handleRate(rate);
              }}
              onClick={rate => {
                handleRate(rate);
              }}
              initialRating={clientRating}
            />
          </p> */}
          <div style={{display: 'flex', flexDirection: 'row', marginTop: 15, color: '#000000'}}>
            <RatingButton color="#014331" onClick={() => handleRate(0)}>0</RatingButton>
            <RatingButton color="#01543D" onClick={() => handleRate(1)}>1</RatingButton>
            <RatingButton color="#026549" onClick={() => handleRate(2)}>2</RatingButton>
            <RatingButton color="#027655" onClick={() => handleRate(3)}>3</RatingButton>
            <RatingButton color="#028761" onClick={() => handleRate(4)}>4</RatingButton>
            <RatingButton color="#02986D" onClick={() => handleRate(5)}>5</RatingButton>
            <RatingButton color="#03A97A" onClick={() => handleRate(6)}>6</RatingButton>
            <RatingButton color="#03BA86" onClick={() => handleRate(7)}>7</RatingButton>
            <RatingButton color="#03CA92" onClick={() => handleRate(8)}>8</RatingButton>
            <RatingButton color="#03DB9E" onClick={() => handleRate(9)}>9</RatingButton>
            <RatingButton color="#04ECAC" onClick={() => handleRate(10)}>10</RatingButton>
          </div>
        </Message>
        }
        {
          clientRating >= 0 &&
        <div style={{flexDirection: 'row', textAlign: 'center'}}>
          {/* <p><button>Voltar</button></p> */}
          <p><RatingButtonSelected number={clientRating} onClick={() => setClientRating(-1)}>{clientRating}</RatingButtonSelected></p>
          <p>{clientRating >= 8 ? "O que você mais gostou?" : "O que poderia melhorar?"}</p>
          <Observations>
            {compliments.map(compliment => (
              <li
                key={compliment}
                onClick={() => {
                  handleSelectRating(compliment);
                }}
                className={
                  selectedItem.includes(compliment) ? 'selected' : ''
                }
              >
                {compliment}
              </li>
            ))}
          </Observations>

          <button onClick={handleSubmit} className="btn-primary" style={{height: 30, background: '#579986', color: '#000000'}}>
            Enviar
          </button>
        </div>
        }
      </Container>
  );
};

export default RatingComponent;
