import React from 'react';

import { Container } from './styles';
import { MdKeyboardArrowRight } from 'react-icons/md';

const StoreComponent = ({ store, client }) => {
  const storeInfo = store.store;
  const avatar = storeInfo && storeInfo.avatar ? storeInfo.avatar.url : null;
  console.log('store', store);
  const members = storeInfo.clients ? storeInfo.clients.length : 0;
  const purchases = storeInfo.purchases ? storeInfo.purchases.length : 0;

  return (
    <Container>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '30%' }}>
          <img src={avatar} style={{ borderRadius: '50%' }} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '70%',
            marginLeft: 25,
            marginTop: 20,
          }}
        >
          <div>
            <h3>{storeInfo.name}</h3>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
            <div
              style={{ width: '34%', display: 'flex', flexDirection: 'column' }}
            >
              <h3 style={{ color: 'green', marginTop: 3, textAlign: 'center' }}>
                {store.cashback ? `R$${store.cashback}` : `${store.points}`}
              </h3>
              <span style={{ textAlign: 'center' }}>
                {store.cashback ? `Saldo` : `Pontos`}
              </span>
            </div>

            <div
              style={{ width: '33%', display: 'flex', flexDirection: 'column' }}
            >
              <h3
                style={{ color: '#151316', marginTop: 3, textAlign: 'center' }}
              >
                {purchases}
              </h3>
              <span style={{ textAlign: 'center' }}>Compras</span>
            </div>

            <div
              style={{ width: '33%', display: 'flex', flexDirection: 'column' }}
            >
              <h3
                style={{ color: '#151316', marginTop: 3, textAlign: 'center' }}
              >
                {storeInfo.rescues.length}
              </h3>
              <span style={{ textAlign: 'center' }}>Resgates</span>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            float: 'right',
            marginRight: 5,
          }}
        >
          <div style={{ margin: 'auto', float: 'right' }}>
            <MdKeyboardArrowRight />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default StoreComponent;
