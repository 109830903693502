import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 97%;
  text-decoration: none;
  background: transparent;
  color: #3E3A41;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dbdad9;

  // font-family: Arial, Helvetica, sans-serif;

  img{
    width: 110px;
    height: 110px;
    margin: 10px;
  }
`;
