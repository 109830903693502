import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  // margin: 8px;
  padding: 10px;
  width: 98%;
  margin: auto;
  background-color: #EBEBEB;
  // border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RatingButton = styled.div`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 50%;
  width: 25px;
  heigth: 25px;
  padding: 3px;
  text-align: center;
  margin-right: 5px;

  ${props =>
    css`
      background: ${props.color};
    `
  };
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;

  h1 {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 900;
  }

  p {
    margin-top: 20px;
    text-align: center;
  }
`;

export const Observations = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  list-style: none;
  margin-top: 16px;
  margin-bottom: 24px;

  li {
    background: #2DC398;
    padding: 10px 15px;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    font-size: 12px;
  }

  li.selected {
    background: #46AA8D};
  }
`;

export const RatingButtonSelected = styled.div`
  background: none;
  color: #000000;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 50%;
  width: 25px;
  heigth: 25px;
  padding: 3px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;

  ${props => props.number === 0 &&
    css`
      background: #014331;
    `
  };

  ${props => props.number === 1 &&
    css`
      background: #01543D;
    `
  };

  ${props => props.number === 2 &&
    css`
      background: #026549;
    `
  };

  ${props => props.number === 3 &&
    css`
      background: #027655;
    `
  };

  ${props => props.number === 4 &&
    css`
      background: #028761;
    `
  };

  ${props => props.number === 5 &&
    css`
      background: #02986D;
    `
  };

  ${props => props.number === 6 &&
    css`
      background: #03A97A;
    `
  };

  ${props => props.number === 7 &&
    css`
      background: #03BA86;
    `
  };

  ${props => props.number === 8 &&
    css`
      background: #03CA92;
    `
  };

  ${props => props.number === 9 &&
    css`
      background: #03DB9E;
    `
  };

  ${props => props.number === 10 &&
    css`
      background: #04ECAC;
    `
  };
`;