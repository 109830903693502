import React from 'react';

import { Container } from './styles';
import formatPhoneString from '../../services/formatPhoneString';
import formatCompleteDate from '../../services/formatCompleteDate';
import Rating from 'react-rating';
import { FiStar } from 'react-icons/fi';

const PurchaseComponent = ({purchase, settings}) => {
  const hasClient = true;
  let rate = null;
  if(purchase.rating.length > 0){
    rate = purchase.rating[0].stars;
  }

  return (
    <Container status={hasClient}>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{width: '35%'}}>
          <div style={{margin: 10}}>
            <img src={purchase.store.avatar ? purchase.store.avatar.url : null}
            style={{width: 60, height: 60, borderRadius: 5}}/>
          </div>
          <div style={{marginLeft: 10, fontSize: 15}}>
            <strong>{purchase.store.name}</strong>
          </div>
        </div>

        <div style={{flexDirection: 'row', width: '65%'}}>
          <div>
            <div style={{margin: 10, fontSize: 16}}>
              Total: R${purchase.total}
            </div>
            <div style={{marginLeft: 10, fontSize: 13}}>
              {purchase.cashback ? 
                `Cashback: R$${purchase.cashback}`
                :
                `Pontos: ${purchase.points} ponto(s)`
              }          </div>
            <div style={{marginLeft: 10, paddingTop: 10, fontSize: 13}}>
              {formatCompleteDate(purchase.createdAt)}
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'column', margin: 10}}>
            Avaliação:
            <div style={{margin: 10, fontSize: 15}}>
              <Rating
                emptySymbol={<FiStar size={28} color="#33333330" />}
                fullSymbol={<FiStar size={28} color="#fb4a20" />}
                style={{fontSize: 10, marginLeft: 20}}
                initialRating={rate || 0}
                readonly
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PurchaseComponent;
